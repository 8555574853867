:root {
    --primary-background: #004e72;
    --secondary-background: #3dc2ff;
}

.logo {
    background: linear-gradient(315deg, var(--primary-background), var(--secondary-background));
    display: block;
    border-radius: 50%;

    &::before {
        content: "";
        position: absolute;
        width: inherit;
        height: inherit;
        background: url("/assets/logo.png");
        background-size: 80%;
        background-repeat: no-repeat;
        background-position: center;
    }
}

.pointer {
    cursor: pointer;
}

:focus { 
    outline: none !important;
}

.clr-input, .clr-select {
  max-width: 100%;
  width: 250px;
}

.clr-control-container, 
.clr-input-wrapper.clr-input {
  padding-left: 0;
  padding-right: 0;
}

.header-0 {
    background: #004e72;
}

.page-title {
    margin-top: 0 !important;
}

@media print {
  @page {size: landscape}
    body * {
      visibility: hidden;
    }
    #section-to-print, #section-to-print * {
      visibility: visible;
    }
    #section-to-print {
      position: absolute;
      zoom: 0.8;
      left: 0;
      top: 0;

      /* * {
        page-break-inside: avoid;
      } */
    }
  }